<template>
  <div class="container">
    <div class="header">
      <img src="../../static/img/about/headimg.png" alt="" />
      <div class="title">关于我们</div>
    </div>
    <div class="about">
      <div class="title d-f j-c a-a">
        <div class="line1"></div>
        <div class="small-square"></div>
        <div class="big-square"></div>
        <div class="title-info">关于睿腾</div>
        <div class="big-square"></div>
        <div class="small-square"></div>
        <div class="line2"></div>
      </div>
      <div class="solution-content d-f">
        <div class="solution-content-left">
          <img src="../../static/img/about/aboutdetail.png" alt="" />
        </div>
        <div class="solution-content-right d-f">
          <div class="content-l-title">河南睿腾信息技术有限公司</div>
          <div class="content-l-info">
            河南睿腾信息技术有限公司成立于2009年，专注于人工智能视觉领域的信息化系统建设与服务。公司以AI+场景为基础致力于人工智能在智慧城市、智慧校园、智慧园区、智慧政务等领域的产品化服务应用。公司拥有多名资深的人工智能领域高级工程师、研发团队、项目经理和运维工程师，通过了ISO9001质量认证体系，取得多项软件荣誉资质。同时公司是商汤科技河南区域总代理及核心战略合作伙伴，致力成为AI智慧化信息建设领域的领跑者。
          </div>
        </div>
      </div>
      <!-- <div class="about-content d-f">
                <div class="content-l d-f">
                    <div class="content-l-title">河南睿腾信息技术有限公司</div>
                    <div class="content-l-info"> 
                             河南睿腾信息技术有限公司成立于2009年，专注于人工智能视觉领域的信息化系统建设与服务。公司以AI+场景为基础致力于人工智能在智慧城市、智慧校园、智慧园区、智慧政务等领域的产品化服务应用。公司拥有多名资深的人工智能领域高级工程师、研发团队、项目经理和运维工程师，通过了ISO9001质量认证体系，取得多项软件荣誉资质。同时公司是商汤科技河南区域总代理及核心战略合作伙伴，致力成为AI智慧化信息建设领域的领跑者。
                    </div>
                    </div>
                <div class="content-r d-f">
                    <img src="../../static/img/about/card1.png" alt="">
                    <img src="../../static/img/about/card2.png" alt="">
                    <img src="../../static/img/about/card3.png" alt="">
                    <img src="../../static/img/about/card4.png" alt="">
                </div>
            </div> -->
    </div>
    <div class="culture-honor">
      <div class="culture">
        <div class="title d-f j-c a-a">
          <div class="line1"></div>
          <div class="small-square"></div>
          <div class="big-square"></div>
          <div class="title-info">企业文化</div>
          <div class="big-square"></div>
          <div class="small-square"></div>
          <div class="line2"></div>
        </div>
        <div class="culture-content">
          <div class="culture-img"></div>
          <div class="culture-slogans">
            <div class="slogan">
              <img src="../../static/img/about/dit.png" alt="" />
              <div class="slogan-title lang-title">企业使命：</div>
              <div class="slogan-info">
                让城市生活更美好，让园区建设更智慧。
              </div>
            </div>
            <div class="slogan">
              <img src="../../static/img/about/dit.png" alt="" />
              <div class="slogan-title lang-title">企业愿景：</div>
              <div class="slogan-info">
                做AI人工智能领域卓越的应用方案提供商。
              </div>
            </div>
            <div class="slogan">
              <img src="../../static/img/about/dit.png" alt="" />
              <div class="slogan-title lang-title">核心价值观：</div>
              <div class="slogan-info">
                为用户创造价值，努力拼搏与创新，敬业诚信，快乐生活。
              </div>
            </div>
            <div class="slogan">
              <img src="../../static/img/about/dit.png" alt="" />
              <div class="slogan-title lang-title">发展理念：</div>
              <div class="slogan-info">
                以人为本，公司与员工共同成长。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="honor">
        <div class="title d-f j-c a-a">
          <div class="line1"></div>
          <div class="small-square"></div>
          <div class="big-square"></div>
          <div class="title-info">荣誉资质</div>
          <div class="big-square"></div>
          <div class="small-square"></div>
          <div class="line2"></div>
        </div>
        <div class="honor-content">
          <div class="swiper-container banner1 swiper-no-swiping">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic1.png" alt="" />
                  </div>
                  <div>VR全景拍摄拼接平台</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic2.png" alt="" />
                  </div>
                  <div>电子沙盘互动系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic3.png" alt="" />
                  </div>
                  <div>会议无感知签到系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic4.png" alt="" />
                  </div>
                  <div>人脸识别布控管理系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic9.png" alt="" />
                  </div>
                  <div>基于GIS的采集系统终端运行支撑服务系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic10.png" alt="" />
                  </div>
                  <div>基于ZigBee蔬菜温室智能检测系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic11.png" alt="" />
                  </div>
                  <div>基于人脸识别的智能宿舍管理系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic12.png" alt="" />
                  </div>
                  <div>基于人脸识别的智能宿舍考勤系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic13.png" alt="" />
                  </div>
                  <div>农产品质量安全追溯综合管理系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic14.png" alt="" />
                  </div>
                  <div>农资监管系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic15.png" alt="" />
                  </div>
                  <div>人脸识别智慧安防管理系统</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-container banner2 swiper-no-swiping">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic5.png" alt="" />
                  </div>
                  <div>人脸识别出入口管理系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic6.png" alt="" />
                  </div>
                  <div>校园人脸考场管理系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic7.png" alt="" />
                  </div>
                  <div>智慧公寓综合管理系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic8.png" alt="" />
                  </div>
                  <div>智慧迎宾系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic16.png" alt="" />
                  </div>
                  <div>人脸识别智慧校园管理平台系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic17.png" alt="" />
                  </div>
                  <div>人脸资源大数据管理平台系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic18.png" alt="" />
                  </div>
                  <div>睿腾机房环境监控系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic19.png" alt="" />
                  </div>
                  <div>睿腾农产品安全追溯系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic20.png" alt="" />
                  </div>
                  <div>睿腾农产品质量安全追溯系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic21.png" alt="" />
                  </div>
                  <div>无公害农产品数据共享服务系统</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="card">
                  <div class="imgwrap">
                    <img src="../../static/img/about/lic22.png" alt="" />
                  </div>
                  <div>智慧课程无感知考勤系统</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev swiper-arw"></div>
          <div class="swiper-button-next swiper-arw"></div>
        </div>
      </div>
      <img class="img1" src="../../static/img/about/team2.png" alt="" />
      <img class="img2" src="../../static/img/about/team1.png" alt="" />
    </div>
    <div class="history">
      <div class="title title-dev d-f j-c a-a">
        <div class="line1"></div>
        <div class="small-square"></div>
        <div class="big-square"></div>
        <div class="title-info">发展历程</div>
        <div class="big-square"></div>
        <div class="small-square"></div>
        <div class="line2"></div>
      </div>
      <div class="his-content d-f a-a">
        <div class="his-content-header">2009年成立至今</div>
        <div class="his-content-info d-f j-c">
          <div class="left">
            <div class="item d-f">
              <img src="../../static/img/about/radius1.png" alt="" />
              <div class="detail">
                <div class="header">公司成立</div>
                <div class="footer" style="text-align: right">
                  2009年9月10日河南睿腾公司成立。
                </div>
              </div>
            </div>
            <div class="item d-f">
              <img src="../../static/img/about/radius3.png" alt="" />
              <div class="detail">
                <div class="header">积累</div>
                <div class="footer">
                  2014年与海康威视、浙大中控达成战略合作。<br />2015年正式更名为河南睿腾信息技术有限公司，同年取得系统集成四级资质。<br />2017年签订并完成了林州公安局天眼项目。
                </div>
              </div>
            </div>
            <div class="item d-f">
              <img src="../../static/img/about/radius5.png" alt="" />
              <div class="detail">
                <div class="header">行业深挖</div>
                <div class="footer">
                  2019年建立AI人脸识别技术研发团队，取得了“人脸识别的智能宿舍管理系统”“人脸资源大数据管理平台系统”等多项软著以及双软企业资质。<br />2020与河南农业大学、河南工业贸易职业技术学院等院校签署合作协议，并积极探索人脸识别在高校中的应用。
                </div>
              </div>
            </div>
          </div>
          <div class="imgcenter">
            <img class="center" src="../../static/img/about/line.png" />
          </div>
          <div class="right">
            <div class="item d-f">
              <div class="detail">
                <div class="header">成长</div>
                <div class="footer">
                  2010年签约戴尔，成为戴尔豫北地区金牌分销商。<br />2012年签订集成类项目-长垣县不动产机房建设项目。
                </div>
              </div>
              <img src="../../static/img/about/radius2.png" alt="" />
            </div>
            <div class="item d-f">
              <div class="detail">
                <div class="header">转型</div>
                <div class="footer">
                  开启以AI+场景为基础致力于人工智能在智慧城市、智慧校园、智慧园区、智慧政务等领域的产品化服务应用。<br />2018年与商汤科技达成战略合作，成为河南区域总代理及核心战略合作伙伴。
                </div>
              </div>
              <img src="../../static/img/about/radius4.png" alt="" />
            </div>
            <div class="item d-f">
              <div class="detail">
                <div class="header">聚势前行</div>
                <div class="footer">
                  2021年挑战与机遇并存，在AI+场景的环境推动下，继续深耕智慧教育和智慧政务方向，聚势前行。
                </div>
              </div>
              <img src="../../static/img/about/radius6.png" alt="" />
            </div>
          </div>
        </div>
        <div class="his-content-footer">未来</div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  data () {
    return {
      mySwiper: null,
    };
  },
  mounted () {
    new Swiper(".banner1", {
      loop: true, // 循环模式选项
      slidesPerView: 4,
      // slidesPerColumn: 2,
      // slidesPerColumnFill:'row',
      spaceBetween: 80,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      // 如果需要前进后退按钮
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    });
    new Swiper(".banner2", {
      loop: true, // 循环模式选项
      slidesPerView: 4,
      // slidesPerColumn: 2,
      // slidesPerColumnFill:'row',
      spaceBetween: 80,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      // 如果需要前进后退按钮
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  min-width: 1200px;
  .header {
    position: relative;
    img {
      width: 100%;
      height: 2.1875rem;
      // position: absolute;
    }
    .title {
      position: absolute;
      top: 0.916667rem;
      left: 2.072917rem;
      height: 0.348958rem;
      font-size: 0.25rem;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 0.348958rem;
      color: #ffffff;
      opacity: 0.87;
    }
  }
  .about {
    .about-content {
      position: relative;
      height: 3.802083rem;
    }
    .solution-content {
      height: 2.244792rem;
      .solution-content-left {
        width: 50%;
        height: 100%;
        img {
          width: 100%;
        }
      }
      .solution-content-right {
        width: 50%;
        height: 100%;
        flex-direction: column;
        opacity: 1;
        border-radius: 4px;
        padding: 0 1.880208rem 0 0.411458rem;
        .content-l-title {
          // font-size: 0.15625rem;
          // font-family: PingFang SC;
          // font-weight: bold;
          // line-height: 0.21875rem;
          // color: #000000;
          // opacity: 0.87;
          font-size: 0.125rem;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0.171875rem;
          color: #333333;
          opacity: 1;
          letter-spacing: 1px;
          &::after {
            content: "";
            display: block;
            width: 0.208333rem;
            height: 3px;
            background: #000000;
            opacity: 0.87;
            margin: 0.104167rem 0;
          }
        }
        .content-l-info {
          width: 2.864583rem;
          height: 1.604167rem;
          font-size: 0.104167rem;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0.182292rem;
          color: #333333;
          // letter-spacing: 60px;
          opacity: 1;
          text-indent: 2em;
        }
      }
    }
    .content-l {
      flex-direction: column;
      min-height: 3.385417rem;
      width: 5.776042rem;
      height: 3.802083rem;
      background: #f6f8fb;
      opacity: 1;
      border-radius: 4px;
      padding: 0.208333rem 0.541667rem 0.208333rem 1.875rem;
      .content-l-title {
        font-size: 0.15625rem;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0.21875rem;
        color: #000000;
        opacity: 0.87;
        &::after {
          content: "";
          display: block;
          width: 0.3125rem;
          height: 3px;
          background: #4285f4;
          opacity: 0.87;
          margin: 0.104167rem 0;
        }
      }
      .content-l-info {
        min-width: 3.359375rem;
        font-size: 0.09375rem;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0.130208rem;
        // height: 318px;
        color: #000000;
        // letter-spacing: 13px;
        opacity: 0.6;
        text-indent: 2em;
        // overflow: hidden;
        // text-overflow:ellipsis;
        // white-space: nowrap;
      }
    }
    .content-r {
      position: absolute;
      right: 0;
      top: 0.3125rem;
      width: 4.65625rem;
      height: 3.177083rem;
      background: red;
      flex-shrink: 0;
      flex-wrap: wrap;
      img {
        width: 50%;
        height: 50%;
      }
    }
  }
  .culture-honor {
    position: relative;
    .img1 {
      width: 3.270833rem;
      height: 4.1875rem;
      position: absolute;
      top: 1.671875rem;
      left: -1.635417rem;
    }
    .img2 {
      width: 1.875rem;
      height: 0.416667rem;
      position: absolute;
      right: 0;
      top: 0.416667rem;
    }
  }
  .culture {
    .culture-content {
      height: 2.604167rem;
      padding: 0 1.875rem;
      position: relative;
      .culture-img {
        width: 2.604167rem;
        height: 2.604167rem;
        border: 1px solid #dddddd;
        border-radius: 50%;
        background-image: url("../../static/img/about/info.png");
        background-position: center center;
        background-size: 2.020833rem 2.020833rem;
        background-repeat: no-repeat;
      }
      .culture-slogans {
        width: 100%;
        position: absolute;
        top: 0;
        .slogan {
          position: absolute;
          text-align: left;
          img,
          div {
            display: inline-block;
            vertical-align: middle;
          }
          &:nth-child(1) {
            top: 0.260417rem;
            left: 2.119792rem;
          }
          &:nth-child(2) {
            top: 0.859375rem;
            left: 2.489583rem;
          }
          &:nth-child(3) {
            top: 1.453125rem;
            left: 2.53125rem;
          }
          &:nth-child(4) {
            top: 2.052083rem;
            left: 2.255208rem;
          }
          img {
            width: 0.104167rem;
            height: 0.104167rem;
            margin-right: 0.15625rem;
          }
          .slogan-title {
            min-width: 0.546875rem;
            height: 0.145833rem;
            font-size: 0.104167rem;
            font-weight: bold;
            line-height: 0.145833rem;
            color: #000000;
            opacity: 0.87;
          }
          .slogan-info {
            width: 2.916667rem;
            height: 0.145833rem;
            min-width: 180px;
            font-size: 0.104167rem;
            font-weight: bold;
            line-height: 0.145833rem;
            color: #000000;
            opacity: 0.6;
          }
        }
      }
    }
  }
  .honor {
    .honor-content {
      padding: 0 1.875rem;
      padding-bottom: 0.260417rem;

      position: relative;
      &:hover .swiper-arw {
        visibility: visible;
      }
      .swiper-button-next {
        position: absolute;
        right: 1.5625rem;
        top: 50%;
        // margin-top: -50px;
        transform: translate(0, -50%);
      }
      .swiper-button-prev {
        position: absolute;
        left: 1.5625rem;
        top: 50%;
        // margin-top: -50px;
        transform: translate(0, -50%);
      }
      .swiper-container {
        padding-bottom: 10px;
        .swiper-wrapper {
          .swiper-slide {
            .card {
              .imgwrap {
                // padding-top: 0.104167rem;
                width: 1.25rem;
                height: 1.25rem;
                background: #f6f8fb;
                opacity: 1;
                border-radius: 4px;
                img {
                  width: 0.755208rem;
                  height: 1.041667rem;
                }
              }
              div {
                height: 0.338542rem;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.09375rem;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 0.130208rem;
                color: #000000;
                opacity: 0.87;
              }
            }
          }
        }
      }
    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-arw {
    position: absolute;
    outline: none;
    width: 40px;
    height: 40px;
    &::after {
      content: "";
    }
    visibility: hidden;
  }
  .swiper-button-prev {
    background-image: url("../../static/img/case-show/arw2.png");
    background-size: 100% 100%;
    position: absolute;
    left: 1.5625rem;
    top: 50%;
    transform: translate(0, -50%);
  }
  .swiper-button-next {
    background-image: url("../../static/img/case-show/arw1.png");
    background-size: 100% 100%;
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translate(0, -50%);
  }
  .history {
    background: #f6f8fb;
    opacity: 1;
    padding-bottom: 0.416667rem;
    .his-content {
      padding: 0 1.875rem;
      flex-direction: column;
      .his-content-header {
        width: 0.770833rem;
        width: 148px;
        height: 0.145833rem;
        font-size: 0.104167rem;
        font-weight: 400;
        line-height: 0.145833rem;
        color: #000000;
        opacity: 0.87;
        text-align: center;
      }
      .his-content-info {
        width: 100%;
        padding: 0.15625rem 0;
        .left {
          width: 2.4375rem;
          .item {
            margin-bottom: 0.708333rem;
            &:first-child {
              margin-top: 0.359375rem;
            }
            &:nth-child(2) {
              margin-bottom: 0.84375rem;
            }
            &:last-child {
              margin-bottom: 0;
            }
            img {
              width: 0.614583rem;
              height: 0.614583rem;
            }
            .detail {
              margin-left: 0.15625rem;
              .header {
                font-size: 0.125rem;
                font-weight: bold;
                line-height: 0.171875rem;
                color: #000;
                text-align: right;
                margin-bottom: 0.0625rem;
              }
              .footer {
                width: 1.494792rem;
                font-size: 0.072917rem;
                font-weight: 400;
                line-height: 0.114583rem;
                color: #000;
                opacity: 0.6;
              }
            }
          }
        }
        .center {
          width: 0.0625rem;
          height: 4.791667rem;
        }
        .right {
          width: 2.421875rem;
          .item {
            margin-bottom: 0.708333rem;
            &:first-child {
              margin-top: 1.015625rem;
            }
            &:nth-child(2) {
              margin-bottom: 1.03125rem;
            }
            &:last-child {
              margin-bottom: 0;
            }
            img {
              width: 0.614583rem;
              height: 0.614583rem;
            }
            .detail {
              margin: 0 0.15625rem;
              .header {
                font-size: 0.125rem;
                font-weight: bold;
                line-height: 0.171875rem;
                color: #000;
                margin-bottom: 0.0625rem;
              }
              .footer {
                width: 1.494792rem;
                font-size: 0.072917rem;
                font-weight: 400;
                line-height: 0.114583rem;
                color: #000;
                opacity: 0.6;
              }
            }
          }
        }
      }
      .his-content-footer {
        font-size: 0.104167rem;
        line-height: 0.145833rem;
        color: #000000;
        opacity: 0.87;
        text-align: center;
      }
    }
  }
}

.title {
  height: 1.067708rem;
  .big-square {
    width: 0.083333rem;
    height: 0.083333rem;
    background: #4285f4;
    opacity: 1;
    margin: 0.072917rem;
    transform: rotate(45deg);
  }
  .small-square {
    width: 0.052083rem;
    height: 0.052083rem;
    background: #4285f4;
    opacity: 0.38;
    transform: rotate(45deg);
  }
  .title-info {
    font-size: 0.166667rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 45px;
    color: #000000;
    opacity: 0.87;
  }
  .line1 {
    width: 40px;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(66, 133, 244, 0.38) 0%,
      rgba(66, 133, 244, 0) 100%
    );
    opacity: 1;
    margin-right: 4px;
  }
  .line2 {
    width: 40px;
    height: 2px;
    background: linear-gradient(
      270deg,
      rgba(66, 133, 244, 0) 0%,
      rgba(66, 133, 244, 0.38) 100%
    );
    opacity: 1;
    margin-left: 4px;
  }
}
.title-dev {
  height: auto;
  padding: 0.416667rem 0 0.15625rem 0;
}
</style>